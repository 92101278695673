<template>
  <v-footer
    class="pt-8 pb-4 pt-md-12 pb-md-8"
    :class="[
      'bg-ui-kit-bg-gray-40 flex-grow-0 px-0'
    ]"
  >
    <v-container>
      <v-row no-gutters>
        <v-col>
          <v-row>
            <AppFooterCol
              cols="12"
              :links="firstColLinks"
            >
              <template #logo>
                <v-img
                  src="/images/logo/medflex-black.svg"
                  alt="МедФлекс"
                  width="159"
                  height="32"
                />
              </template>
            </AppFooterCol>
            <AppFooterCol
              cols="12"
              class="mt-5"
              :links="contactsLinks"
            />
          </v-row>
        </v-col>
        <AppFooterCol
          class="mt-10 mt-md-0"
          cols="12"
          md="4"
          title="Наши проекты"
          :links="projectsLinks"
        >
          <template #sub-column>
            <div
              v-if="locale.isCis"
              class="ml-md-6 mt-6 mt-md-4"
            >
              <div
                v-for="(link, index) in projectSubColumnLinks"
                :key="link.linkText"
                class="text-body-1 text-ui-kit-text"
                :class="{
                  'mt-6 mt-md-3': index !== 0,
                }"
              >
                <span
                  v-if="link.text"
                  class="text-decoration-none mr-1"
                >
                  {{ link.text }}
                </span>
                <NuxtLinkWithoutTrailingSlash
                  class="text-body-1 text-decoration-underline text-ui-kit-text"
                  target="_blank"
                  :href="link.href"
                  external
                >
                  {{ link.linkText }}
                </NuxtLinkWithoutTrailingSlash>
              </div>
            </div>
          </template>
        </AppFooterCol>
        <AppFooterCol
          class="mt-10 mt-md-0"
          cols="12"
          md="4"
          title="Условия использования"
          :class="{ 'app-footer-col_indent': locale.isCis }"
          :links="documentsLinks"
        />
      </v-row>
      <div
        class="mt-4 mt-md-13"
        :class="['d-flex align-start']"
      >
        <v-img
          v-if="locale.isRu"
          src="/images/software-register.svg"
          alt="Единый реестр российских программ для ЭВМ"
          width="64"
          height="76"
          class="mr-8 flex-grow-0 d-none d-md-inline"
        />
        <div>
          <p class="text-body-2 text-ui-kit-text-secondary">
            Программное обеспечение МедФлекс предоставляется на основании подписания договора — безвозмездно.
          </p>
          <p
            v-if="locale.isRu"
            class="mt-1 text-body-2 text-ui-kit-text-secondary"
          >
            Плата взимается за совершённые транзакции в соответствии с тарифами, определёнными договором.
          </p>
          <v-img
            v-if="locale.isRu"
            src="/images/software-register.svg"
            alt="Единый реестр российских программ для ЭВМ"
            width="64"
            height="76"
            class="mt-10 flex-grow-0 d-inline-block d-md-none"
          />
          <p
            class="mt-4 mt-md-3"
            :class="['text-body-2 text-ui-kit-text-info']"
          >
            © 2011-{{ new Date().getFullYear() }}, {{ medRocketName }}
          </p>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script lang="ts" setup>
import type { AppFooterColLink } from '~/components/AppFooter/AppFooterCol.vue'

const locale = useAppLocale()
const {
  medRocketUrl,
  rabotaMedRocketUrl,
  helpMfUrl,
  pdUrl,
  mlUrl,
  mtUrl,
  ptUrl,
  pbUrl,
  ydocTj,
  ydocKg,
  ydocUz,
  ydocBy,
  ydocKz,
  mfSecurityPolicy,
  mfUserAgreement,
  mfLicenseAgreement,
  mfUserManual
} = useUrls()

const medRocketName = computed(() => {
  if (locale.isRu) {
    return 'ООО «МедРокет»'
  }

  return 'МедРокет'
})

const firstColLinks = computed<AppFooterColLink[]>(() => {
  if (locale.isRu) {
    return [
      {
        linkText: 'О компании',
        href: medRocketUrl
      },
      {
        prg: true,
        linkText: 'Карьера у нас',
        href: rabotaMedRocketUrl
      },
      {
        linkText: 'База знаний',
        href: helpMfUrl
      }
    ]
  }

  return [
    {
      linkText: 'О компании',
      href: medRocketUrl
    },
    {
      linkText: 'База знаний',
      href: helpMfUrl
    }
  ]
})

const projectsLinks = computed<AppFooterColLink[]>(() => {
  if (locale.isRu) {
    return [
      {
        linkText: 'ПроДокторов',
        href: pdUrl
      },
      {
        linkText: 'МедТочка',
        href: mtUrl
      },
      {
        linkText: 'ПроТаблетки',
        href: ptUrl
      },
      {
        linkText: 'ПроБолезни',
        href: pbUrl
      },
      {
        linkText: 'МедЛок',
        href: mlUrl
      }
    ]
  }

  return [
    {
      linkText: 'YDoc – Беларусь',
      href: ydocBy
    },
    {
      linkText: 'YDoc – Казахстан',
      href: ydocKz
    },
    {
      linkText: 'YDoc – Кыргызстан',
      href: ydocKg
    },
    {
      linkText: 'YDoc – Таджикистан',
      href: ydocTj
    },
    {
      linkText: 'YDoc – Узбекистан',
      href: ydocUz
    }
  ]
})

const projectSubColumnLinks: AppFooterColLink[] = [
  {
    linkText: 'МедЛок',
    href: mlUrl
  },
  {
    linkText: 'ПроТаблетки',
    href: ptUrl
  },
  {
    linkText: 'ПроБолезни',
    href: pbUrl
  }
]

const documentsLinks = computed<AppFooterColLink[]>(() => locale.isRu
  ? [
    {
      linkText: 'Политика безопасности',
      href: mfSecurityPolicy
    },
    {
      linkText: 'Пользовательское соглашение',
      href: mfUserAgreement
    },
    {
      linkText: 'Лицензионный договор',
      href: mfLicenseAgreement
    },
    {
      linkText: 'Руководство пользователя',
      href: mfUserManual
    }
  ]
  : [
    {
      linkText: 'Политика безопасности',
      href: mfSecurityPolicy
    },
    {
      linkText: 'Пользовательское соглашение',
      href: mfUserAgreement
    },
    {
      linkText: 'Лицензионный договор',
      href: mfLicenseAgreement
    }
  ]
)

const contactsLinks = computed<AppFooterColLink[]>(() => {
  if (locale.isRu) {
    return [
      {
        text: 'Телефон:',
        linkText: '8 800 707-31-29',
        href: 'tel:8(800)707-31-29'
      },
      {
        text: 'Email:',
        linkText: 'help@medrocket.ru',
        href: 'mailto:help@medrocket.ru'
      }
    ]
  }

  return [
    {
      text: 'Телефон:',
      linkText: '+7 988 460-05-65',
      href: 'tel:+7(988)460-05-65'
    },
    {
      text: 'Email:',
      linkText: 'clinic@medrocket.me',
      href: 'mailto:clinic@medrocket.me'
    }
  ]
})
</script>

<style lang="scss">
@use 'sass:map';

.app-footer-col {
  &_indent {
    @media #{map.get($display-breakpoints, 'md-and-up')} {
      padding-left: 100px !important;
    }
  }
}
</style>
