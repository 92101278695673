<template>
  <v-app-bar
    class="app-bar flex-grow-0 flex-shrink-0 app-bar_bordered-desktop ssr-fix-header"
    :class="{'app-bar_bordered': isScrolled }"
    :height="$vuetify.display.mdAndUp ? DESKTOP_HEIGHT : MOBILE_HEIGHT"
    flat
  >
    <v-container class="px-4 px-md-2 py-2 py-md-4">
      <v-row class="align-center">
        <NuxtLink
          :to="linkTo({ name: 'country' })"
          @click="handleClickMobileLink('country')"
        >
          <v-img
            src="/images/logo/medflex.svg"
            alt="МедФлекс"
            class="cursor-pointer flex-grow-0"
            :width="159"
            :height="32"
          />
        </NuxtLink>

        <div class="d-none d-md-flex justify-end align-center flex-grow-1 app-bar-links">
          <NuxtLink
            v-for="(link) in links"
            :key="link.text"
            class="app-bar__link"
            :to="linkTo(link.to)"
            :class="{
              'app-bar__link_active': activeLink === link.to.name && isMounted
            }"
            @click="handleClickLink(link.to.name)"
          >
            {{ link.text }}
          </NuxtLink>
        </div>

        <v-spacer class="d-none d-md-block" />

        <HeaderCountrySelect
          v-if="$featureFlag('feature_split_landing_data_by_country')"
          class="d-none d-md-flex mr-10"
        />

        <v-btn
          color="primary"
          variant="tonal"
          class="d-none d-md-flex"
          :href="loginLink"
        >
          Войти
        </v-btn>
        <v-spacer class="d-md-none" />
        <v-btn
          class="d-md-none"
          color="ui-kit-text-info"
          title="меню"
          :icon="buttonMenuIcon"
          @click="clickMenuHandler"
        />
      </v-row>
    </v-container>
  </v-app-bar>
  <client-only>
    <v-navigation-drawer
      v-model="isShowDrawer"
      order="1"
      class="d-md-none"
      :width="isShowDrawer ? '100%' : 0"
      disable-resize-watcher
      disable-route-watcher
      floating
      touchless
      persistent
    >
      <NuxtLink
        v-for="(link) in links"
        :key="link.text"
        class="app-bar__link"
        :to="linkTo(link.to)"
        :class="{
          'app-bar__link_active': activeLink === link.to.name && isMounted
        }"
        @click="handleClickMobileLink(link.to.name)"
      >
        <v-list-item
          v-ripple
          class="pa-4"
          :title="link.text"
        />
      </NuxtLink>
      <v-divider class="ma-4" />
      <div>
        <NuxtLinkWithoutTrailingSlash
          text-class="py-4 d-flex align-center"
          class="text-decoration-none"
          :href="locale.isRu ? 'tel:8(800)707-31-29' : 'tel:+7(988)460-05-65'"
          external
        >
          <v-sheet
            v-ripple
            class="d-flex align-center py-4"
            :width="mdBreakpoint"
          >
            <v-icon
              color="ui-kit-text-info"
              class="mx-4"
              size="24"
              icon="ui-icon-phone-none"
            />
            <span
              class="text-decoration-none text-body-1 text-ui-kit-text"
              v-text="locale.isRu ? '8 800 707-31-29' : '+7 988 460-05-65'"
            />
          </v-sheet>
        </NuxtLinkWithoutTrailingSlash>
      </div>
      <NuxtLinkWithoutTrailingSlash
        text-class="py-4 d-flex align-center"
        class="text-decoration-none"
        :href="locale.isRu ? 'mailto:help@medrocket.ru' : 'mailto:clinic@medrocket.me'"
        external
      >
        <v-sheet
          v-ripple
          class="d-flex align-center py-4"
          :width="mdBreakpoint"
        >
          <v-icon
            color="ui-kit-text-info"
            class="mx-4"
            size="24"
            icon="ui-icon-mail"
          />
          <span
            class="text-decoration-none text-body-1 text-ui-kit-text"
            v-text="locale.isRu ? 'help@medrocket.ru' : 'clinic@medrocket.me'"
          />
        </v-sheet>
      </NuxtLinkWithoutTrailingSlash>
      <template v-if="$featureFlag('feature_split_landing_data_by_country')">
        <v-divider class="mx-4 mt-4 mb-5" />
        <CountrySelectBottomSheet />
      </template>
      <v-btn
        color="primary"
        variant="tonal"
        class="app-bar__log-in-button"
        :href="loginLink"
      >
        Войти
      </v-btn>
      <v-tooltip
        z-index="0"
        scroll-strategy="block"
        content-class="d-none"
        title="меню тултип"
        :model-value="isShowDrawer"
      />
    </v-navigation-drawer>
  </client-only>
</template>

<script lang="ts" setup>
import { DESKTOP_HEIGHT, MOBILE_HEIGHT } from '~/core/constants/appBar'
import { CountrySelectBottomSheet, HeaderCountrySelect } from '~/features/CountrySelect'

const locale = useAppLocale()

const route = useRoute()
const { y: scrollY } = useScroll(document)
const activeLink = computed(() => route.name)
const loginLink = '/login/?next=/cabinet/'
/* Исправление Hydration style mismatch https://github.com/vuetifyjs/vuetify/issues/19015 */
const isMounted = useMounted()
const isShowDrawer = ref(false)
const buttonMenuIcon = computed(() => {
  return isShowDrawer.value
    ? 'ui-icon-close-not-a-circle'
    : 'ui-icon-menu'
})
const mdBreakpoint = '1000px'
const links = locale.isRu
  ? [
    {
      text: 'Клиникам',
      to: {
        name: 'country-clinics'
      }
    },
    {
      text: 'Страховым',
      to: {
        name: 'country-insurance'
      }
    },
    {
      text: 'Сервисам',
      to: {
        name: 'country-services'
      }
    },
    {
      text: 'Интеграции с МИС',
      to: {
        name: 'country-mis'
      }
    }
  ]
  : [
    {
      text: 'Клиникам',
      to: {
        name: 'country-clinics'
      }
    },
    {
      text: 'Сервисам',
      to: {
        name: 'country-services'
      }
    },
    {
      text: 'Интеграции с МИС',
      to: {
        name: 'country-mis'
      }
    }
  ]

const isScrolled = computed(() => {
  const appBarHeightMobile = 56

  return scrollY.value > appBarHeightMobile
})

const clickMenuHandler = () => {
  isShowDrawer.value = !isShowDrawer.value
}

async function handleClickMobileLink(name: string) {
  isShowDrawer.value = false
  await nextTick()
  handleClickLink(name)
}

function handleClickLink(name: string) {
  if (route.name !== name) {
    return
  }

  scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped>
.app-bar {
  height: var(--header-height);

  @media (min-width: $desktop-breakpoint) {
    &_bordered-desktop {
      border-bottom: 1px solid $t-ui-kit-bg-gray-60;
    }
  }

  &_bordered {
    border-bottom: 1px solid $t-ui-kit-bg-gray-60;
  }

  &__log-in-button {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    width: calc(100% - 32px)
  }

  &__link {
    font-weight: 400;
    color: $t-black;
    text-decoration: none;

    &_active, &:hover {
      color: $t-primary
    }
  }

  &-links {
    column-gap: 16px;

    @media (min-width: 1000px) {
      column-gap: 32px;
    }
  }
}
</style>
