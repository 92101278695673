<template>
  <v-bottom-sheet
    v-if="locale.currentCountry"
    content-class="bg-ui-kit-bg-gray-0"
  >
    <template #activator="{ props: bottomSheetProps }">
      <CountryListItem
        class="px-4"
        v-bind="bottomSheetProps"
        :append-icon="{
          icon: 'ui-icon-arrow-right',
          color: 'ui-kit-icon-secondary'
        }"
        :country="locale.currentCountry"
      />
    </template>

    <p class="mt-4 mx-4 text-h6">Сменить страну сайта</p>

    <v-divider class="mt-4 mb-6" />

    <p class="mb-6 mx-4">Версия сайта для разных стран может отличаться содержанием и контактами.</p>

    <CountryLinksList
      spacing="8"
      item-padding-x="16"
    />
  </v-bottom-sheet>
</template>

<script setup lang="ts">
import CountryLinksList from '~/features/CountrySelect/ui/CountryLinksList.vue'
import CountryListItem from './CountryListItem.vue'

const locale = useAppLocale()
</script>
