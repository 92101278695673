<template>
  <v-app
    v-if="!$featureFlag('feature_split_landing_data_by_country') || locale.currentCountry"
    full-height
  >
    <AppHeader />
    <v-main class="ssr-fix-main">
      <slot />
      <AppFooter />
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <CountrySelectDialog />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { CountrySelectDialog } from '~/features/CountrySelect'

const locale = useAppLocale()
</script>
