<template>
  <div>
    <v-menu
      v-if="locale.currentCountry"
      width="220"
      location="bottom right"
      scroll-strategy="close"
    >
      <template #activator="{ props: menuProps }">
        <v-btn
          v-bind="menuProps"
          min-width="56"
          height="40"
        >
          <v-img
            width="24"
            max-width="24"
            height="24"
            :src="`/images/flags/${locale.currentCountry.toLocaleLowerCase()}.svg`"
          />
        </v-btn>
      </template>

      <CountryLinksList
        class="py-2"
        show-selected
      />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import CountryLinksList from './CountryLinksList.vue'

const locale = useAppLocale()
</script>

<style lang="scss">
.country-select-button {
  &:hover {
    background: $t-ui-kit-bg-gray-40;
  }
}
</style>
