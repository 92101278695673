import { type CountryCode, COUNTRY_CODE } from '~/core/i18n'

export const getLinkByCountry = (country: CountryCode) => {
  const {
    mfUrl,
    mfUrlBy,
    mfUrlKz,
    mfUrlCis
  } = useUrls()

  switch (country) {
    case COUNTRY_CODE.BY:
      return mfUrlBy
    case COUNTRY_CODE.KZ:
      return mfUrlKz
    case COUNTRY_CODE.RU:
      return mfUrl
    default:
      return `${mfUrlCis}/${country.toLowerCase()}/`
  }
}
