<template>
  <v-list
    class="d-flex flex-column"
    :style="{ rowGap: toCssSize(spacing) } "
  >
    <CountryListItem
      v-for="country in COUNTRIES_IN_ORDER"
      :key="country"
      :style="{
        paddingLeft: toCssSize(itemPaddingX),
        paddingRight: toCssSize(itemPaddingX)
      }"
      :country="country"
      :href="getLinkByCountry(country)"
      :append-icon="getIcon(country)"
    />
  </v-list>
</template>

<script setup lang="ts">
import { COUNTRY_CODE, type CountryCode } from '~/core/i18n'
import { getLinkByCountry } from '../utils/getLinkByCountry'
import CountryListItem from './CountryListItem.vue'

type Props = {
  showSelected?: boolean
  spacing?: number | string
  itemPaddingX?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  spacing: 0,
  itemPaddingX: 12
})

const COUNTRIES_IN_ORDER = [
  COUNTRY_CODE.RU,
  COUNTRY_CODE.BY,
  COUNTRY_CODE.KZ,
  COUNTRY_CODE.KG,
  COUNTRY_CODE.TJ,
  COUNTRY_CODE.UZ
]

const locale = useAppLocale()

function getIcon(country: CountryCode) {
  if (!props.showSelected) {
    return {
      icon: 'ui-icon-arrow-right',
      color: 'ui-kit-icon-secondary'
    }
  }

  return locale.currentCountry && locale.currentCountry === country
    ? {
      icon: 'ui-icon-check',
      color: 'primary'
    }
    : null
}
</script>
